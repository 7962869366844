// pages/login.js
import React, { useState } from 'react';


const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !password) {
        setError('Please fill in all fields');
        return;
    }
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    

    try {
        const response = await fetch('https://code.poemville.com.ng/api/login', {
        method: 'POST',
        body: formData
        });
        // Handle response from the API
        if (response.ok) {
            const data = await response.json(); 
            if(data.status === true){
                setError('');
                window.location= '/dashboard';
            }else{
                setError(data.message);
            }
            

        } else {
            setError('Invalid username or password');
        }
    } catch (error) {
        console.error('Login failed:', error);
        setError('An error occurred while logging in');
    }
  };

  return (
    <div className="container d-flex justify-content-center">
        <div className='row'>
        <div className='col-md-12'>
            <h1 className="text-center mb-4">Login</h1>
        </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div className='col-md-12'>
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label>Username</label>
                <input
                    className='form-control'
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </div>
            <div className="mb-3 form-check">
                <label>Password:</label>
                <input
                    className='form-control'
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>
        </div>

      
    </div>
  );
};

export default Login;