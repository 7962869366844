import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Login";
import Dashboard from "./Dashboard";
import React, { useState } from 'react';

function App() {
  const [isAuth, setIsAuth] = useState(false);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard isAuth={isAuth} />} />
        </Routes>
      </BrowserRouter>

      { isAuth && <Dashboard isAuth={isAuth} />}
    </div>
  );
  
}

export default App;
 