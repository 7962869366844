import React from "react";

const Dashboard = ({isAuth}) => {    
    if(isAuth){
        window.location= '/';
    }
    

    return(
        <>
            <h1>Dashboard Page</h1>
            <p>Welcome to the dashboard!</p>
        </>
    )
}

export default Dashboard;